export enum AccountCustomData {
  jobTitle = 'jobTitle',
  logoId = 'logoId',
  industry = 'industry',
  size = 'size',
  locationAddress = 'locationAddress',
  locationLng = 'locationLng',
  locationLat = 'locationLat',
  type = 'type',
}

export enum AccountType {
  PlaybookAdmin = 'PlaybookAdmin',
  ServiceProvider = 'ServiceProvider',
  Organisation = 'Organisation',
}

export type Account = {
  id: string;
  parentId: string;
  name: string;
  customData?: Record<AccountCustomData, string>;
};

export type RoleMappings = {
  [key: string]: string;
};

export type SamlConfig = {
  id: string;
  accountId: string;
  autoRegistration: boolean;
  redirectUrl: string;
  entityId: string;
  issuer: string;
  metadataUrl: string;
  singleSignOnUrl: string;
  singleLogoutUrl: string;
  signingCertificate: string;
  roleMappings: RoleMappings | null;
  active: boolean;
};
